<div class="body-app">
    <app-header-public  *ngIf="!hiddenHeader && !burgerMenuActive && !isShareholder" [linkListPublic]="linkListPublic" [isOnboarding]="isOnboarding"></app-header-public>
    <app-header-private *ngIf="hiddenHeader && !burgerMenuActive && role !== null && !isShareholder && !isShareholdersLogin" [linkListPrivate]="linkListPrivate"></app-header-private>
    <app-shareholders-header *ngIf="isShareholder && isShareholdersLogin"></app-shareholders-header>
    <app-side-nav *ngIf="burgerMenuActive && !isShareholder" class="sideNav_container" (openedEvent)="receiveOpenedSideNav($event)" [linkListPublic]="linkListPublic" [linkListPrivate]="linkListPrivate" [hiddenHeader]="hiddenHeader" [isOnboarding]="isOnboarding"></app-side-nav>
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
    <app-footer [isAuth]="_login" ></app-footer>
</div>

