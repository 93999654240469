import { MaterialModule } from 'src/app/shared/material.module';
import { Component } from '@angular/core';
import {
  MatSnackBarAction,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  providers: [CookieService],
  standalone: true,
  imports: [MaterialModule, MatSnackBarModule],
})
export class CookiesComponent {
  _cookieValue: any;
  _isConcent: any = false;
  constructor(
    private cookieService: CookieService,
    private snackBarRef: MatSnackBarRef<MatSnackBarAction>,
    private router: Router
  ) {}
  setCookies() {
    this.cookieService.set('isConcent', this._isConcent);
    this._cookieValue = this.cookieService.get('isConcent');
    this.snackBarRef.dismiss();
  }
  declineCookies() {
    this.snackBarRef.dismiss();
  }

  goToCookies() {
    this.router.navigate(['politica-de-cookies'])
  }
}
